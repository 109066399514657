import React from "react";
import "../assets/css/floating.css";
import phoneIcon from "../assets/img/phoneIcon.svg";
import phoneIconYellow from "../assets/img/phoneIconYellow.svg";

const Floating = (data) => {
  const url = window.location.pathname;

  let phone = data.data.phoneNumber_rm
  let numberIcon = data.data.phoneNumberIcon_rm
  if (url === "/va"){
      phone = data.data.phoneNumber_va
      numberIcon = data.data.phoneNumberIcon_va
  }

  return (
    <div className="floating-button" type="button">
      <div className="button-title">
        <img src={phoneIcon} alt="Phone" className="phoneIconWeb" />
        <img src={phoneIconYellow} alt="Phone" className="phoneIconMobile" />
        <p>Llamanos</p>
      </div>
      <a href={`tel:${phone}`}>
        <b>{numberIcon}</b>
      </a>
    </div>
  );
};

export default Floating;
